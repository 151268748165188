/* Product List, Vue Component */
<template>
  <v-card id="product-list">
    <v-card-title>Products</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <Can I="create" on="Administration">
          <v-btn color="primary" class="me-3" :to="{ name: 'product-create' }">
            <v-icon size="18" class="me-1">{{ icons.mdiPlus }}</v-icon>
            <span>Create Product</span>
          </v-btn>
        </Can>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">

        <v-text-field
          v-model="search.all"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Fields"
          class="mr-4"
        ></v-text-field>



        <ProductCategorySelect class="mr-4" v-model="filter.ProductCategoryId" :outlined="true" :inverted="false" :hide-details="true" :dense="true" />



        <v-select
          v-model="filter.Enabled"
          :items="filterItems.Enabled"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Filter Enabled"
          class="mr-4"
        ></v-select>



        <v-select
          v-model="filter.Public"
          :items="filterItems.Public"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Filter Public"
          class="mr-4"
        ></v-select>


      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedItems"
      :headers="tableColumns"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      class="text-no-wrap"
    >

    <template #[`item.Name`]="{item}">
          <router-link class="font-weight-medium text-decoration-none"
        :to="{ name: 'product-view', params: { id: item.Id } }">
      {{ item.Name }}
    </router-link>

    </template>


		<template #[`item.Price`]="{item}">
      {{ Display.Money(item.Price) }}
    </template>

		<template #[`item.Enabled`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.Enabled).variant">{{ booleanIcons(item.Enabled).icon }}</v-icon>
    </template>

		<template #[`item.Public`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.Public).variant">{{ booleanIcons(item.Public).icon }}</v-icon>
    </template>

    <template #[`item.Guesstimate`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.Guesstimate).variant">{{ booleanIcons(item.Guesstimate).icon }}</v-icon>
    </template>

    <template #[`item.Rotatable`]="{item}">
      <v-icon size="18" :color="booleanIcons(item.Rotatable).variant">{{ booleanIcons(item.Rotatable).icon }}</v-icon>
    </template>


      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <ActionsDropdown :item="item" :actionOptions="actionOptions" />
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import store from '@/store'

import {
  mdiPlus,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'
import * as Validators from '@/datahelpers/validation'
import * as Display from '@/datahelpers/displayTypes'
import ActionsDropdown from '@/views/helpers/ActionsDropdown'
import ProductCategorySelect from '@/views/productcategory/ProductCategorySelect'
import { Can } from '@casl/vue'

export default {
  components: {
    ActionsDropdown,
    ProductCategorySelect,
    Can
  },
  setup() {

  // define cols
  const tableColumns = [
    { text: 'Name', value: 'Name' },
		{ text: 'Code', value: 'Code' },
		{ text: 'Order', value: 'SortOrder' },
		{ text: 'ProductCategory Name', value: 'ProductCategoryId_Name' },
		// { text: 'Length', value: 'Length' },
		// { text: 'Width', value: 'Width' },
		// { text: 'Height', value: 'Height' },
    { text: 'Dimensions', value: 'Dimensions' },
		{ text: 'Price', value: 'Price' },
		{ text: 'Example', value: 'Example' },
		{ text: 'TTLMD', value: 'TTLMDProductCode' },
		// { text: 'Volume Tolerance', value: 'VolumeTolerance' },
		// { text: 'Dimension Tolerance', value: 'DimensionTolerance' },
		{ text: 'Enabled', value: 'Enabled' },
		{ text: 'Public', value: 'Public' },
    { text: 'Guesstimate', value: 'Guesstimate' },
    { text: 'Rotatable', value: 'Rotatable' },
    {
      text: '',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  // threee params for fetching
  const search = ref({});
  const filter = ref({});
  const options = ref({
    sortBy: ['SortOrder'],
    sortDesc: [false],
    itemsPerPage: 20
  });
  const footerProps = {
    'items-per-page-options': [10, 20, 50, 100, -1]
  };

  // items
  const items = computed(() => store.state.app.Products || []);
  const totalItems = computed(() => store.state.app.ProductsTotal || 0);
  const selectedItems = ref([]);
  const loading = ref(false);

  // data for filter fields
  const filterItems = ref({
    Enabled: [{"text":"Enabled","value":true},{"text":"Disabled","value":false}],
		Public: [{"text":"Enabled","value":true},{"text":"Disabled","value":false}]
  });

  // method for data loading via vuex
  const fetchData = () => {
    store
      .dispatch('app/fetchProducts', {
        search: search.value,
        options: options.value,
        filter: filter.value,
      })
       .then(response => {
         loading.value = false
       })
      .catch(error => {
        console.log(error)
      })
  }

  watch([search, filter, options], () => {

    // start loading
    loading.value = true
    fetchData()
  }, { deep: true });


  const booleanIcons = status => {
    if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
    return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
  }


    const actionOptions = [
      { title: 'View', icon: mdiEyeOutline, target: 'product-view' },
      { title: 'Edit', icon: mdiPencilOutline, target: 'product-edit' }
    ]

    return {
      tableColumns,
      search,
      filterItems,
      filter,
      options,
      totalItems,
      items,
      loading,
      selectedItems,
      actionOptions,
      footerProps,
      booleanIcons,
      Validators,
      Display,
      icons: {
        mdiPlus,
        //mdiDeleteOutline,
        //mdiEyeOutline,
        //mdiPencilOutline,
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
